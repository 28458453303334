<template>
  <section>
    <add-new-category-banner :category-id="categoryId" @added="loadBanners" position="left"/>
    <v-card class="mt-4">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th style="width: 150px">
              #
            </th>
            <th>
              Link
            </th>
            <th style="width: 150px">
              Actiuni
            </th>
          </tr>
          </thead>
          <tbody>
          <tr :key="index" v-for="(item, index) in bannerData">
            <td>
              <v-img :src="item.image.src" height="150" width="150"/>
            </td>
            <td>
              {{ item.link }}
            </td>
            <td>
              <delete-button @delete="deleteItem(item)"/>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </section>
</template>

<script>
  import DeleteButton from "../../../../../components/general-form/DeleteButton";
  import AddNewCategoryBanner from "../AddNewCategoryBanner";
  export default {
    name: "SideBanner",
    components: {AddNewCategoryBanner, DeleteButton},
    data() {
      return {
        banners: {
          data: []
        }
      }
    },
    computed: {
      categoryId() {
        return this.$route.params.id
      },
      bannerData() {
        return this.banners.data
      }
    },
    methods: {
      loadBanners() {
        this.$http.get(`product-categories/${this.categoryId}/banners?page_size=10000&position=left`)
          .then(({data}) => {
            this.banners = data
          })
      },
      deleteItem(item) {
        this.$http.delete(`product-categories/${this.categoryId}/banners/${item.id}`)
          .then(() => {
            this.loadBanners()
          })
      }
    },
    mounted() {
      this.loadBanners()
    },
    watch: {
      categoryId() {
        this.loadBanners()
      }
    }
  }
</script>
